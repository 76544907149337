.SettingsEntryPoint {
  z-index: 10;
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba(153, 153, 153, 0.75);
  border: none;
  height: 50px;
  width: 50px;
  opacity: 0.7;
  cursor: pointer;
  transition: all 0.2s var(--expo);
}

.SettingsEntryPoint:hover {
  opacity: 1;
  height: 120px;
  width: 120px;
}

.SettingsEntryPoint__icon {
  width: 20px;
  color: #000;
}