.MessageBar {
  padding: 20px;
  background: #eaeaea;
  color: #4e4e4e;
  font-weight: bold;
  display: inline-block;
  animation: MessageBar__animate-in 250ms ease-out forwards;
}

@keyframes MessageBar__animate-in {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translate(0);
    opacity: 1;
  }
}

@media screen and (max-width: 700px) {
  .MessageBar {
    padding: 10px 15px;
  }
}