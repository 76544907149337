.SettingsDialog {
  position: absolute;
  z-index: 99999;
  top: 0;
  left: 50%;
  width: 70vw;
  min-width: 340px;
  max-height: 100vh;
  overflow: scroll;
  transition: all 600ms var(--expo);
}
.SettingsDialog section {
  background: rgb(46, 47, 59);
  padding: 10px 40px;
}

.SettingsDialog--shown {
  transform: translate(-50%, 0) rotateY(0);
}

.SettingsDialog--hidden {
  transform: translate(-50%, -100%) rotateY(20deg);
}

.SettingsDialog__content {
  min-height: 100%;
}

.SettingsDialog__dim {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 99998;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  transition: opacity 400ms ease-out;
}

.SettingsDialog__dim--shown {
  opacity: 1;
}

.SettingsDialog__dim--hidden {
  opacity: 0;
  pointer-events: none;
}