.GameBoard {
  display: grid;
  height: 100vh;
  width: 100vw;
  grid-template-rows: 110px 1fr;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
    'Stats StrokeMeter MessageArea'
    '_ StrokeMeter J';
  background-position: center;
  background-size: cover;

}

.GameBoard > * { z-index: 2; }
.GameBoard > .Porn { z-index: 1; }

@media screen and (max-width: 700px) {
  .GameBoard {
    grid-template-rows: 110px 1fr 110px;
    grid-template-columns: 1fr;
    grid-template-areas:
      'Stats'
      'StrokeMeter'
      'MessageArea';
  }
}