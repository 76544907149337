.Hypno,
.Hypno--changed {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  font-weight: bold;
  padding: 4vw;
  opacity: 0.005;
  -webkit-text-stroke: black 1px;
}

.Hypno--changed {
  animation: 1s var(--expo) 0s 1 normal forwards running hypnoHighlight;
}

@keyframes hypnoHighlight {
  from {
    opacity: 0.3;
  }

  to {
    opacity: 0;
  }
}