.ReleaseNotes__container {
  padding: 10px 45px;
  margin: 20px 0;
  background: rgb(46, 47, 59);
}

#ReleaseNotes__button {
  background: none;
  border: none;
  color: #fff;
  position: relative;
  margin: 5px 0;
  padding: 15px;
  font-size: 1.25rem;
  cursor: pointer;
  display: block;
  width: 100%;
  text-align: left;
}

#ReleaseNotes__button:hover,
#ReleaseNotes__button:focus {
  background: rgba(89, 90, 119, 0.4);
}

#ReleaseNotes__button::after {
  position: absolute;
  right: 15px;
}

#ReleaseNotes__button[aria-expanded=false]::after {
  content: '▼';
}

#ReleaseNotes__button[aria-expanded=true]::after {
  content: '▲';
}

#ReleaseNotes__content {
  overflow: scroll;
  transition: all 0.4s var(--expo);
  background: rgb(71, 73, 90);
}

#ReleaseNotes__content[aria-hidden=false] {
  max-height: 300px;
  padding: 17px;
}

#ReleaseNotes__content[aria-hidden=true] {
  max-height: 0;
  padding: 0 17px;
}

#ReleaseNotes__content h2 {
  font-size: 1.3rem;
  margin: 10px 0 0 0;
}

#ReleaseNotes__content ul {
  padding: 0 0 0 20px;
  margin: 0 0 25px 0;
  line-height: 20px;
}