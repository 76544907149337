.MessageArea {
  grid-area: MessageArea;
  padding: 25px;
  text-align: right;
}

.MessageArea__prompt {
  justify-content: flex-end;
}

.MessageArea__prompt .settings-button {
  font-size: 1rem;
  height: auto;
  padding: 10px 14px;
  margin: 0 5px;
}

.MessageArea__prompt .settings-button:last-child {
  margin-right: 0;
}

@media screen and (max-width: 700px) {
  .MessageArea {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: flex-end;
    font-size: 0.85rem;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .MessageArea__prompt {
    flex: 1 100%;
  }
}