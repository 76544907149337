.settings-group,
.settings-group--disabled {
  flex: 1 1 340px;
  padding: 5px 15px;
  background: rgba(122, 123, 163, 0.123);
  margin: 15px;
  border: none;
  border-left: 2px solid #595a77;
  transition: all 0.2s ease-in-out;
}

.settings-group--disabled {
  opacity: 0.4;
  background: rgba(0, 0, 0, 0.123);
}

fieldset.settings-group,
fieldset.settings-group--disabled {
  position: relative;
}

fieldset.settings-group legend,
fieldset.settings-group--disabled legend {
  background: #595a77;
  padding: 4px 8px;
}

.settings-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 10px 0;
}

.settings-row > strong {
  font-size: 0.8rem;
  font-weight: normal;
  color: #b9bad6;
}

.settings-row > em,
.settings-row > strong {
  display: block;
  margin-bottom: 5px;
  width: 100%;
}

.settings-row em {
  font-size: 0.8rem;
  font-weight: normal;
  color: #9495ad;
  display: block;
  margin-bottom: 10px;
}

.settings-innerrow {
  flex: 1 1 100%;
  margin-bottom: 10px;
}

.settings-innerrow::after {
  content: '';
  display: block;
  width: 25px;
  height: 1px;
  background: #595a77;
  margin: 15px auto 5px auto;
}

.settings-innerrow:last-child {
  margin-bottom: 0;
}

.settings-innerrow:last-child::after {
  display: none;
}

.settings-row span {
  display: inline-block;
  margin: 0 10px;
  font-size: 0.8rem;
}

.settings-row label {
  cursor: pointer;
}

.settings-row label span {
  margin-right: 15px;
  margin-left: 0;
  min-width: 70px;
  font-size: 0.9rem;
  color: #b9bad6;
}

/* Form Controls */

.settings-row input[type=range] {
  width: 130px;
}

.settings-row input[type=text] {
  height: 21px;
  border: none;
  margin-right: 10px;
  padding: 2px 5px;
}

.settings-row input[type=submit],
.settings-row button,
.settings-row a.settings-button {
  height: 21px;
  background: #595a77;
  border: 0;
  font-size: 0.75rem;
  color: #FFF;
  padding: 4px 6px;
  cursor: pointer;
}

.settings-row > button + button,
.settings-innerrow > button + button {
  margin-left: 10px;
}

.settings-row .settings-option,
.settings-row .settings-option--enabled,
.settings-row .settings-option--disabled {
  display: block;
  height: auto;
  width: 100%;
  text-align: left;
  background: rgba(89, 90, 119, 0.4);
  margin: 10px 0;
  padding: 5px 10px;
}

.settings-row .settings-option > *,
.settings-row .settings-option--enabled > *,
.settings-row .settings-option--disabled > * {
  margin: 3px 0;
  display: block;
}

.settings-row .settings-option--enabled:hover {
  background: rgba(67, 68, 90, 0.4);
}

.settings-row .settings-option--disabled:hover {
  background: rgba(76, 76, 102, 0.507);
}

.settings-row .settings-option--disabled {
  background: rgba(51, 51, 68, 0);
  color: rgba(255, 255, 255, 0.438);
}

.settings-row input[type=submit]:hover,
.settings-row button:hover {
  background: #4b4c66;
}

.settings-row input[type=submit]:active,
.settings-row button:active {
  background: #2b2c3a;
}

.settings-row textarea{
  background: #2b2c3a;
  color: #FFF;
  padding: 10px;
  border: none;
  resize: vertical;
}

.settings-row .emoji-icon {
  font-style: normal;
  font-size: 1.3em;
  padding: 2px;
  background: #1d1c20;
  height: 3ex;
  width: 3ex;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 0 10px;
}

fieldset.settings-group .settings-cover,
fieldset.settings-group--disabled .settings-cover {
  position: absolute;
  top: -10px;
  left: -2px;
  background: rgba(14, 14, 21, 0.68);
  width: calc(100% + 2px);
  height: calc(100% + 5px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transform: scale(1.05);
}

fieldset.settings-group .settings-cover h3,
fieldset.settings-group--disabled .settings-cover h3 {
  margin-bottom: 0;
}

fieldset.settings-group .settings-cover p,
fieldset.settings-group--disabled .settings-cover p {
  width: 100%;
}
